<template>
    <div>
        <cabecera>
            <div class="contenedor hero hero--inicio">
                <div class="texto">
                    <h1>Productos y Soluciones de Limpieza</h1>
                    <p>Productos de limpieza de alta calidad, entregados en tu hogar, oficina y/o empresa lo más pronto posible.</p>
                    <router-link to="/productos/quimicos" class="boton">Ver Productos</router-link>
                </div>
                <picture>
                    <source srcset="/img/hero.avif" type="image/avif">
                    <source srcset="/img/hero.webp" type="image/webp">
                    <source srcset="/img/hero.png" type="image/png">
                    <img class="hero--inicio__imagen" src="/img/hero.png" alt="Imagen de productos SASS">
                </picture>
            </div>
        </cabecera>
        <div class="nosotros">
            <section class="contenedor nosotros--contenido">
                <h2>Sobre Nosotros</h2>
                <div class="heading-grande-20">Sobre Nosotros</div>
                <div class="contenedor-600">
                    <p>Somos una empresa ecuatoriana productora y comercializadora de insumos de limpieza para el cuidado de tu hogar, oficina y/o empresa.</p>
                    <div class="diferenciadores">
                        <div class="diferenciador diferenciador--1">
                            <img class="diferenciador__icono diferenciador__icono--1" src="/img/diferenciadores/diff1.svg" alt="Excelente Calidad - Icono">
                            <p class="diferenciador__titulo diferenciador__titulo--1">Excelente Calidad</p>
                        </div>
                        <div class="diferenciador diferenciador--2">
                            <img class="diferenciador__icono diferenciador__icono--2" src="/img/diferenciadores/diff2.svg" alt="Precios Justos - Icono">
                            <p class="diferenciador__titulo diferenciador__titulo--2">Precios Justos</p>
                        </div>
                        <div class="diferenciador diferenciador--3">
                            <img class="diferenciador__icono diferenciador__icono--3" src="/img/diferenciadores/diff3.svg" alt="Entrega Rápida - Icono">
                            <p class="diferenciador__titulo diferenciador__titulo--3">Entrega Rápida</p>
                        </div>
                    </div>
                    <router-link to="/nosotros" class="boton">Más sobre Nosotros</router-link>
                </div>
            </section>
        </div>
        <div class="categorias--seccion">
            <section class="contenedor categorias--contenido">
                <h2>Líneas de Productos</h2>
                <div class="heading-grande-25">Líneas de Productos</div>
                <div class="categorias">
                    <router-link v-for="categoria in categorias" :key="categoria.id" :to="categoria.ruta" class="categoria" :class="'categoria--' + categoria.id">
                        <h3 class="categoria__nombre" :class="'categoria__nombre--' + categoria.id">{{ categoria.nombre }}</h3>
                        <div class="categoria__fondo" :class="'categoria__fondo--' + categoria.id">
                            <picture>
                                <source :srcset="categoria.ruta_imagen_avif" type="image/avif">
                                <source :srcset="categoria.ruta_imagen_webp" type="image/webp">
                                <source :srcset="categoria.ruta_imagen_jpg" type="image/jpg">
                                <img class="categoria__imagen" :src="categoria.ruta_imagen_jpg" :alt="'Imagen de la categoría de ' + categoria.nombre">
                            </picture>
                        </div>
                    </router-link>
                </div>
            </section>
            <footer>

            </footer>
        </div>
        <pie></pie>
        <contacto-flotante/>
    </div>
</template>
<script>
import cabecera from '@/components/header.vue';
import pie from '@/components/footer.vue';
import sourceData from '@/data.json';
export default {
    components: {cabecera, pie},
    data() {
        return {
            categorias: sourceData.categorias
        }
    },
}
</script>